import React, { useState, useEffect, useContext } from "react";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Nav from "./Nav";
import { TransitionGroup } from "react-transition-group";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Grid from "@material-ui/core/Grid";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import Tooltip from "@material-ui/core/Tooltip";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import SwipeableViews from "react-swipeable-views";
import Fade from "@material-ui/core/Fade";
import Slide from "@material-ui/core/Slide";
import { Link } from "react-router-dom";
import SearchBar from "material-ui-search-bar";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { UserContext } from "./UserContext";
import TablePagination from '@material-ui/core/TablePagination';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  table: {
    // width: "100%",
  },
  appBar: {
    backgroundColor: "black",
  },
  searchBar: {
    width: "70%",
  },
  filterBar: {
    backgroundColor: "white",
    width: "30%",
    marginLeft: "1%",
  },
  searchContainer: {
    display: "flex",
    width: "100%",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function DataTable(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [value, setValue] = React.useState(0);
  const [condition, setCondition] = React.useState(100);

  const conditions = [
    {
      value: 500,
    },
    {
      value: 1000,
    },
    {
      value: 1500,
    },
    {
      value: 2000,
    },
    {
      value: 2500,
    },
    {
      value: 3000,
    },
    {
      value: 3000,
    },
    {
      value: 3500,
    },
    {
      value: 4000,
    },
    {
      value: 4500,
    },
    {
      value: 5000,
    },
    {
      value: 5500,
    },
    {
      value: 6000
    }
  ];


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    fetchApplications(value, +event.target.value)
    setPage(0);
  };
  const [state, setState] = React.useState({
    checkedB: false,
    label: "Show Inactive Patients",
  });

  const [applications, setApplications] = useState();
  const [pendingApplications, setPendingApplications] = useState();
  const [acceptedApplications, setAcceptedApplications] = useState();
  const [deniedApplications, setDeniedApplications] = useState();

  const { user } = useContext(UserContext);
  // GET/Fetch all patients, listener for patients

  const fetchApplications = (value, condition) => {
    fetch(`${process.env.REACT_APP_API_URL}/api/applications${value === 1 ? '/accepted' : value === 2 ? '/denied' : ''}?limit=${condition}`, { credentials: 'include' })
      .then((response) => response.json())
      .then((json) => filterApplications(json))
  };

  const filterApplications = (applications) => {
    setApplications(applications);
    const result = applications && applications.filter(application => application.status === "Pending");  
    setPendingApplications(result);
    const result2 = applications && applications.filter(application => application.status === "Accepted");  
    setAcceptedApplications(result2);
    const result3 = applications && applications.filter(application => application.status === "Denied");  
    setDeniedApplications(result3);
  };


  useEffect(() => {
    fetchApplications(value, condition)
  }, []);

  const handleChangeCondition = (event) => {
    setCondition(event.target.value);
    fetchApplications(value, event.target.value)
  };

  const theme = useTheme();

  const handleTabsChange = (event, newValue) => {
    setValue(newValue);
    fetchApplications(newValue, condition)
  };

  const [searching, setSearching] = React.useState("Applications");

  const handleChange = (event) => {
    setSearching(event.target.value);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  // declaring state for searched value to be passed to requestSearch from the SearchBar component

  const [searched, setSearched] = React.useState("");

  // declaring state in order to keep state that was there prior to searching
  // that way we return to the original state without having to refresh, when we are done searching

  const [previousState, setPreviousState] = React.useState([]);

  // request search function that filters the search based on "searching" value (doctors/patients)
  // basic .includes function that returns the filtered patients on real time
  // we also set the previous state to the original patients state before searching, if the previousState.length equals to 0, which means
  // that we havent searched for anything yet

  const requestSearch = (searchedVal) => {
    if (searching === "Applications") {
      const filteredApplications = applications.filter((application) => {
        return application.steamID
          .toLowerCase()
          .includes(searchedVal.toLowerCase());
      });

      setApplications((prevState) => {
        if (previousState.length == 0) {
          setPreviousState(prevState);
        }
        return filteredApplications;
      });
    } else if (searching === "Staff") {
      const filteredApplications = applications.filter((application) => {
        if (application.handler && application.handler.name) {
          return application.handler.name
          .toLowerCase()
          .includes(searchedVal.toLowerCase());
        }
      });

      setApplications((prevState) => {
        if (previousState.length == 0) {
          setPreviousState(prevState);
        }
        return filteredApplications;
      });
    } else if (searching === "DiscordID") {
      const filteredApplications = applications.filter((application) => {
        return application.discordID
          .toLowerCase()
          .includes(searchedVal.toLowerCase());
      });

      setApplications((prevState) => {
        if (previousState.length == 0) {
          setPreviousState(prevState);
        }
        return filteredApplications;
      });
    }
  };

  // we cancel the search and set the patients to the original state through previousState

  const cancelSearch = () => {
    setSearched("");
    setApplications(previousState);
    // requestSearch(searched);
  };
  return (
    <div>
      <Nav />
      <br></br>
      <Container maxWidth="md">
      <Select value={condition} onChange={handleChangeCondition}>
        {conditions.map((condition, index) => (
          <MenuItem key={index} value={condition.value}>
            {condition.value}
          </MenuItem>
        ))}
      </Select>
      <AppBar position="static" className={classes.appBar}>
      <Tabs
            value={value}
            onChange={handleTabsChange}
            aria-label="simple tabs example"
          >
            <Tab label="Outstanding" {...a11yProps(0)} />
            {/* <Tab label="Handled" {...a11yProps(1)} /> */}
            {/* <Tab label="I Handled" {...a11yProps(2)} /> */}
            <Tab label="Accepted" {...a11yProps(1)} />
            <Tab label="Denied" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
      </Container>
      <Container maxWidth="md">
        {/* <div style={{display: 'inline-flex'}}>
          <Button startIcon={<LocalHospitalIcon />} color="primary" variant="outlined" className={classes.button}>Add</Button>
          <span style={{marginLeft: "550px", marginTop: "8px"}}>
      </span>
      </div> */}
        <br></br>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0}>
            {/* overflow hides the scrollbar at the table on patient GET/POST/UPDATE/DELETE */}
            <TableContainer
              className={classes.table}
              component={Paper}
              style={{ overflowY: "hidden" }}
            >
              <div style={{ width: "auto", overflowX: "scroll" }}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <strong>DiscordID</strong>
                      </TableCell>
                      <TableCell>
                        <strong>SteamID</strong>
                      </TableCell>
                      {/* <TableCell align="right">
                        <strong>SteamID</strong>
                      </TableCell> */}
                      {/* <TableCell align="right">
                        <strong>Username</strong>
                      </TableCell> */}
                      <TableCell align="right">
                        <strong>Staff Handler</strong>
                      </TableCell>
                      <TableCell align="right">
                        <strong>Status</strong>
                      </TableCell>
                      <TableCell align="right">
                        <strong>Reason</strong>
                      </TableCell>
                      <TableCell align="center">
                        <strong>Action</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pendingApplications && pendingApplications.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((application) => (
                          <Slide
                            direction="up"
                            in={applications}
                            mountOnEnter
                            unmountOnExit
                          >
                            <TableRow key={application._id}>
                              <TableCell>{application.discordID.substring(0, 80)}</TableCell>
                              <TableCell align="right">{application.steamID.substring(0, 80)}</TableCell>
                              {application.handler &&
                                application.handler.name != undefined && (
                                  <TableCell align="right">
                                    {application.handler.name}
                                  </TableCell>
                                )}
                              {!application.handler &&
                                  <TableCell align="right">
                                    Pending handler
                                  </TableCell>
                                }
                                <TableCell align="right">
                                {application.status}
                              </TableCell>
                              <TableCell align="right">
                                Pending
                              </TableCell>
                              <TableCell align="center">
                                <Tooltip title="Details">
                                  <IconButton
                                    aria-label="details"
                                    component={Link}
                                    to={`/applications/${application._id}`}
                                  >
                                    <PersonPinIcon />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          </Slide>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </TableContainer>
            <TablePagination
        rowsPerPageOptions={[100, 200, 300, 600]}
        component="div"
        count={pendingApplications && pendingApplications.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
          </TabPanel>
          {/* <TabPanel value={value} index={1}>
          <TableContainer
              className={classes.table}
              component={Paper}
              style={{ overflowY: "hidden" }}
            >
              <div style={{ width: "auto", overflowX: "scroll" }}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <strong>DiscordID</strong>
                      </TableCell>
                      <TableCell>
                        <strong>SteamID</strong>
                      </TableCell>
                      <TableCell align="right">
                        <strong>Staff Handler</strong>
                      </TableCell>
                      <TableCell align="right">
                        <strong>Status</strong>
                      </TableCell>
                      <TableCell align="right">
                        <strong>Reason</strong>
                      </TableCell>
                      <TableCell align="center">
                        <strong>Action</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {handledApplications && handledApplications.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((application) => (
                          <Slide
                            direction="up"
                            in={applications}
                            mountOnEnter
                            unmountOnExit
                          >
                            <TableRow key={application._id}>
                              <TableCell>{application.discordID.substring(0, 80)}</TableCell>
                              <TableCell align="right">{application.steamID.substring(0, 80)}</TableCell>
                              {application.handler &&
                                application.handler.name != undefined && (
                                  <TableCell align="right">
                                    {application.handler.name}
                                  </TableCell>
                                )}
                                {!application.handler &&
                                  <TableCell align="right">
                                    System
                                  </TableCell>
                                }
                              <TableCell align="right">
                                {application.status}
                              </TableCell>
                              <TableCell align="right">
                                {application.reason}
                              </TableCell>
                              <TableCell align="center">
                                <Tooltip title="Details">
                                  <IconButton
                                    aria-label="details"
                                    component={Link}
                                    to={`/applications/${application._id}`}
                                  >
                                    <PersonPinIcon />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          </Slide>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </TableContainer>
            <TablePagination
        rowsPerPageOptions={[100, 200, 300, 600]}
        component="div"
        count={handledApplications && handledApplications.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
          </TabPanel> */}
          <TabPanel value={value} index={1}>
          <TableContainer
              className={classes.table}
              component={Paper}
              style={{ overflowY: "hidden" }}
            >
              <div style={{ width: "auto", overflowX: "scroll" }}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <strong>DiscordID</strong>
                      </TableCell>
                      <TableCell>
                        <strong>SteamID</strong>
                      </TableCell>
                      {/* <TableCell align="right">
                        <strong>SteamID</strong>
                      </TableCell> */}
                      {/* <TableCell align="right">
                        <strong>Username</strong>
                      </TableCell> */}
                      <TableCell align="right">
                        <strong>Staff Handler</strong>
                      </TableCell>
                      <TableCell align="right">
                        <strong>Status</strong>
                      </TableCell>
                      <TableCell align="right">
                        <strong>Reason</strong>
                      </TableCell>
                      <TableCell align="center">
                        <strong>Action</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {acceptedApplications && acceptedApplications.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((application) => (
                          <Slide
                            direction="up"
                            in={applications}
                            mountOnEnter
                            unmountOnExit
                          >
                            <TableRow key={application._id}>
                              <TableCell>{application.discordID.substring(0, 80)}</TableCell>
                              <TableCell align="right">{application.steamID.substring(0, 80)}</TableCell>
                              {application.handler &&
                                application.handler.name != undefined && (
                                  <TableCell align="right">
                                    {application.handler.name}
                                  </TableCell>
                                )}
                              <TableCell align="right">
                                {application.status}
                              </TableCell>
                              <TableCell align="right">
                                {application.reason}
                              </TableCell>
                              <TableCell align="center">
                                <Tooltip title="Details">
                                  <IconButton
                                    aria-label="details"
                                    component={Link}
                                    to={`/applications/${application._id}`}
                                  >
                                    <PersonPinIcon />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          </Slide>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </TableContainer>
            <TablePagination
        rowsPerPageOptions={[100, 200, 300, 600]}
        component="div"
        count={acceptedApplications && acceptedApplications.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
          </TabPanel>
          <TabPanel value={value} index={2}>
          <TableContainer
              className={classes.table}
              component={Paper}
              style={{ overflowY: "hidden" }}
            >
              <div style={{ width: "auto", overflowX: "scroll" }}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <strong>DiscordID</strong>
                      </TableCell>
                      <TableCell>
                        <strong>SteamID</strong>
                      </TableCell>
                      {/* <TableCell align="right">
                        <strong>SteamID</strong>
                      </TableCell> */}
                      {/* <TableCell align="right">
                        <strong>Username</strong>
                      </TableCell> */}
                      <TableCell align="right">
                        <strong>Staff Handler</strong>
                      </TableCell>
                      <TableCell align="right">
                        <strong>Status</strong>
                      </TableCell>
                      <TableCell align="right">
                        <strong>Reason</strong>
                      </TableCell>
                      <TableCell align="center">
                        <strong>Action</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {deniedApplications && deniedApplications.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((application) => (
                          <Slide
                            direction="up"
                            in={applications}
                            mountOnEnter
                            unmountOnExit
                          >
                            <TableRow key={application._id}>
                              <TableCell>{application.discordID.substring(0, 80)}</TableCell>
                              <TableCell align="right">{application.steamID.substring(0, 80)}</TableCell>
                              {application.handler &&
                                application.handler.name != undefined && (
                                  <TableCell align="right">
                                    {application.handler.name}
                                  </TableCell>
                                )}
                                {!application.handler &&
                                  <TableCell align="right">
                                    System
                                  </TableCell>
                                }
                              <TableCell align="right">
                                {application.status}
                              </TableCell>
                              <TableCell align="right">
                                {application.reason}
                              </TableCell>
                              <TableCell align="center">
                                <Tooltip title="Details">
                                  <IconButton
                                    aria-label="details"
                                    component={Link}
                                    to={`/applications/${application._id}`}
                                  >
                                    <PersonPinIcon />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          </Slide>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </TableContainer>
            <TablePagination
        rowsPerPageOptions={[100, 200, 300, 600]}
        component="div"
        count={deniedApplications && deniedApplications.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
          </TabPanel>
        </SwipeableViews>
      </Container>
    </div>
  );
}
