import React, { useState, useEffect, useRef, useContext } from "react";
import Nav from "./Nav";
// importing the main container
import Container from "@material-ui/core/Container";
// main card import that holds patient details and the action buttons
// ------------------------
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
// importing the button from material ui
import Button from "@material-ui/core/Button";
import NoteAdd from "@material-ui/icons/Comment";
// ------------------------
// styles for the components from material ui
import { makeStyles } from "@material-ui/core/styles";
// standard text from material ui
import Typography from "@material-ui/core/Typography";
// replacement for the classic <hr> from material ui
import Divider from "@material-ui/core/Divider";
// Table
// ------------------------
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// ------------------------
// Icons for the buttons
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
// ------------------------
// Tooltip which shows details about an action on hover
import Tooltip from "@material-ui/core/Tooltip";
// From table
import Paper from "@material-ui/core/Paper";
// List from Material UI
// ------------------------
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Avatar from "@material-ui/core/Avatar";
// ------------------------
// Dialog component from Material UI
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
//---------
import { Link } from "react-router-dom";
import { useLocation, useHistory } from "react-router";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import { UserContext } from "./UserContext";

export default function PatientDetails({ match }, props) {
  //---------

  const { user, setUser } = useContext(UserContext);
  // declaring state for the specific patient
  const [application, setApplication] = useState({});
  const [reason, setReason] = useState();

  // ensure to add the "/" infront of api/patients, so end result is /api/patients so it doesnt get affected by router
  // GET/Fetch specific patient,findByID
  // fetches for updates continuously, might have to fix it in the future
  useEffect(() => {
    if (user.role === 'admin' || user.role === 'staff') {
    fetch(`${process.env.REACT_APP_API_URL}/api/applications/${match.params.id}`, {credentials: 'include'})
      .then((response) => response.json())
      .then((json) => setApplication(json));
    } else {
      fetch(`${process.env.REACT_APP_API_URL}/api/applications/standard/${match.params.id}`, {credentials: 'include'})
      .then((response) => response.json())
      .then((json) => setApplication(json));
    }
  }, []);

  const history = useHistory();

  const [editPatient, setEditPatient] = React.useState({});

  // Dialog
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openReason, setOpenReason] = React.useState(false);

  const handleClickOpenReason = () => {
    setOpenReason(true);
  };

  const handleCloseReason = () => {
    setOpenReason(false);
  };


  const onDenyApplicationSubmit = (e) => {
    setOpen(false);
    e.preventDefault();
    let updatedPatient = {
      ...application,
      status: "Denied",
      handler: user._id,
      reason: reason
    };
    fetch(`${process.env.REACT_APP_API_URL}/api/applications/${application._id}`, {
      method: "PUT",
      credentials: 'include',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ editApplication: updatedPatient }),
    })
      .then((res) => res.json())
      .then((data) => {
        setApplication(updatedPatient);
      });
  };

  const onAcceptApplicationSubmit = (e) => {
    setOpen(false);
    e.preventDefault();
    let updatedPatient = {
      ...application,
      status: "Accepted",
      handler: user._id,
      reason: reason
    };
    fetch(`${process.env.REACT_APP_API_URL}/api/applications/${application._id}`, {
      method: "PUT",
      credentials: 'include',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ editApplication: updatedPatient }),
    })
      .then((res) => res.json())
      .then((data) => {
        setApplication(updatedPatient);
      });
  };

  //---------

  const useStyles = makeStyles((theme) => ({
    root: {
      minWidth: 150,
    },
    rootTab: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      display: "flex",
      minWidth: 150,
      maxHeight: 170,
      overflow: "auto",
    },
    tabs: {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
    // tabPanel1: {
    //   backgroundColor: "red",
    //   width: "700",
    // },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)",
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    noteActionPos: {
      top: "30%",
    },
  }));

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const classes = useStyles();
  // for form validation in notes
  const form = useRef();
  const form2 = useRef();

  return (
    <div>
      <Nav />
      <br></br>
      <Container maxWidth="md">
        {/* MAIN DETAILS */}
        <Card className={classes.root} variant="outlined">
          <CardContent>
            <Typography variant="h6" component="h2">
              APPLICATION DETAILS - <span>{application.status} {application.status === 'Denied' ? 
              <Button
                size="small"
                color="primary"
                variant="outlined"
                onClick={handleClickOpenReason}
              >
                REASON
              </Button> 
              : ''}</span> 
            </Typography>
            <Divider />
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              SUBMISSION DATE
            </Typography>
            <Typography variant="body2" component="p">
              {new Date(application.sentApplicationOn).toDateString()}
            </Typography>
            <Divider />
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              DISCORD PROFILE ID
            </Typography>
            <Typography variant="body2" component="p">
              {application.discordID}
            </Typography>
            <Divider />
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
            WHAT IS YOUR CHARACTER'S FIRST AND LAST NAME?
            </Typography>
            <Typography variant="body2" component="p">
              {application.characterName}
            </Typography>
            <Divider />
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              STEAMID 64
            </Typography>
            <Typography variant="body2" component="p">
              {application.steamID}
            </Typography>
            <Divider />
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              EXPLAIN HOW YOU FOUND OUT ABOUT OUR SERVER?
            </Typography>
            <Typography variant="body2" component="p">
              {application.foundOutExplanation}
            </Typography>
            <Divider />
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              DO YOU HAVE ROLEPLAY EXPERIENCE ON ANY OTHER GAMES? IF SO, WHICH?
            </Typography>
            <Typography variant="body2" component="p">
              {application.roleplayExperience}
            </Typography>
            <Divider />
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              WHY SHOULD WE ACCEPT YOU?
            </Typography>
            <Typography variant="body2" component="p">
              {application.joinReason}
            </Typography>
            <Divider />
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              ENTER THE HIDDEN WORD FOUND IN OUR RULES.
            </Typography>
            <Typography variant="body2" component="p">
              {application.hiddenWord}
            </Typography>
            <Divider />
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
            WHAT DOES NVL MEAN? WHEN DOES IT APPLY?
            </Typography>
            <Typography variant="body2" component="p">
              {application.valueOfLifeExplanation}
            </Typography>
            <Divider />
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
            GIVE A DETAILED BACKSTORY OF YOUR CHARACTER BEFORE ENTERING THE ZONE, INCLUDING THE TRAITS DESCRIBED BELOW.
            </Typography>
            <Typography variant="body2" component="p">
              {application.backgroundStory}
            </Typography>
            <Divider />
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
            GIVE 3 POSITIVE TRAITS YOUR CHARACTER WILL POSSESS DURING THEIR LIFE ON STALKERZ. EXPLAIN HOW THEY WILL AFFECT YOUR CHARACTER.
            </Typography>
            <Typography variant="body2" component="p">
              {application.positiveTraits}
            </Typography>
            <Divider />
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
            GIVE 3 NEGATIVE TRAITS YOUR CHARACTER WILL POSSESS DURING THEIR LIFE ON STALKERZ. EXPLAIN HOW THEY WILL AFFECT YOUR CHARACTER.
            </Typography>
            <Typography variant="body2" component="p">
              {application.negativeTraits}
            </Typography>
            <Divider />
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
            WHEN IS IT ALLOWED TO GO OUT OF CHARACTER WHEN PLAYING ON THE SERVER?
            </Typography>
            <Typography variant="body2" component="p">
              {application.allowedOOC}
            </Typography>
            <Divider />
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
            YOU ARE HOLDING SOMEONE UP, YOU GIVE THEM A DEMAND TO PUT THEIR HANDS UP OR YOU WILL SHOOT. HOW MUCH TIME MUST YOU GIVE THEM TO COMPLY?
            </Typography>
            <Typography variant="body2" component="p">
              {application.initiationComplianceSeconds}
            </Typography>
            <Divider />
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
            YOU JUST SEEN SOMEONE BREAK A RULE RIGHT INFRONT OF YOU, WHAT DO YOU DO?
            </Typography>
            <Typography variant="body2" component="p">
              {application.rulebreakInfrontOfYou}
            </Typography>
            <Divider />
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
            WHAT ARE THE CURRENT FACTIONS ON THE SERVER?
            </Typography>
            <Typography variant="body2" component="p">
              {application.currentFactions}
            </Typography>
            <Divider />
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
            WHICH FACTION, IF ANY, WOULD YOU BE MOST INTERESTED IN? TELL US WHY?
            </Typography>
            <Typography variant="body2" component="p">
              {application.interestedFaction}
            </Typography>
            <Divider />
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
            GIVE 3 FULL SCENARIOS THAT EXPLAIN WHEN YOU SHOULD VALUE YOUR LIFE.
            </Typography>
            <Typography variant="body2" component="p">
            {application.ScenariosValueOfLife}
            </Typography>
            <Divider />
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
            TELL US WHAT YOU THINK GOOD SPORTSMANSHIP IS AND WHY IT IS IMPORTANT.
            </Typography>
            <Typography variant="body2" component="p">
              {application.sportsmanship}
            </Typography>
            <Divider />
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
            IN YOUR OWN WORDS, GIVE A DETAILED EXPLANATION OF THE NLR RULE.
            </Typography>
            <Typography variant="body2" component="p">
              {application.nlrExplanation}
            </Typography>
            <Divider />
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
            YOU WERE PART OF AN ENCOUNTER IN WHICH YOU DIED, WHEN CAN YOU GRAB YOUR GEAR?
            </Typography>
            <Typography variant="body2" component="p">
              {application.gearGrab}
            </Typography>
            <Divider />
          </CardContent>
          {application.status === 'Pending' &&
          <div style={{ width: "auto", overflowX: "scroll" }}>
          {user.role === "admin" && 
            <CardActions>
              <Button
                size="small"
                color="primary"
                variant="outlined"
                onClick={handleClickOpen}
              >
                HANDLE APPLICATION
              </Button>
            </CardActions>
          }
           {user.role === "staff" && 
            <CardActions>
              <Button
                size="small"
                color="primary"
                variant="outlined"
                onClick={handleClickOpen}
              >
                HANDLE APPLICATION
              </Button>
            </CardActions>
          }
          </div>
          }
        </Card>
        <br></br>
        <Dialog
          open={open}
          fullWidth
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          {/* <form className={classes.root} noValidate autoComplete="off" onSubmit={onNoteSubmit}> */}
          <ValidatorForm
            ref={form}
            onError={(errors) => console.log(errors)}
          >
            <DialogTitle id="form-dialog-title">HANDLE APPLICATION</DialogTitle>
            <DialogContent>
              <DialogContentText>
                APPLICANT {application.discordID}
              </DialogContentText>
              <TextValidator
                autoFocus
                margin="dense"
                id="description"
                name="note[text]"
                onChange={(e) => setReason(e.target.value)}
                value={reason}
                validators={["required"]}
                errorMessages={["Please a reason for the denial!"]}
                label="Reason for acceptance or denial"
                type="text"
                fullWidth
                multiline
                rows={4}
                variant="outlined"
              />
            </DialogContent>
            { reason &&
            <DialogActions>
            {application.status === "Accepted" && (
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  onClick={onDenyApplicationSubmit}
                  >
                  DENY APPLICATION
                </Button>
              )}
              {application.status === "Denied" && (
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  onClick={onAcceptApplicationSubmit}
                  >
                  ACCEPT APPLICATION
                </Button>
              )}
               {application.status === "Pending" && (
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  onClick={onDenyApplicationSubmit}
                  >
                  DENY APPLICATION
                </Button>
              )}
              {application.status === "Pending" && (
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  onClick={onAcceptApplicationSubmit}
                  >
                  ACCEPT APPLICATION
                </Button>
              )}
            </DialogActions>
            }
          </ValidatorForm>
          {/* </form> */}
        </Dialog>
        <Dialog
          open={openReason}
          fullWidth
          onClose={handleCloseReason}
          aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">DENIAL REASON</DialogTitle>
            <DialogContent>
              <DialogContentText>
                APPLICANT {application.discordID}
              </DialogContentText>
              <Typography variant="body2" component="p">
              {application.reason}
            </Typography>
            </DialogContent>
            <DialogActions>
            <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  onClick={handleCloseReason}
                  >
                  CLOSE
                </Button>
            </DialogActions>
        </Dialog>
      </Container>
    </div>
  );
}
