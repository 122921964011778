import React, { useState, useEffect, useRef, useContext } from "react";
import Nav from "./Nav";
import Toolbar from "@material-ui/core/Toolbar";
import Alert from '@material-ui/lab/Alert';
// importing the main container
import Container from "@material-ui/core/Container";
// main card import that holds patient details and the action buttons
// ------------------------
import Card from "@material-ui/core/Card";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
// replacement for the classic <hr> from material ui
import Divider from "@material-ui/core/Divider";
// standard text from material ui
import { UserContext } from "./UserContext";
import Typography from "@material-ui/core/Typography";
// styles for the components from material ui
import { makeStyles } from "@material-ui/core/styles";
// input
import TextField from "@material-ui/core/TextField";
// button
import Button from "@material-ui/core/Button";
// date picker for admission
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
// Dropdown
import MenuItem from "@material-ui/core/MenuItem";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

export default function PatientCreate(props) {
  // make a post request - DONE
  // make the prescriptions an array, watch colt steele bootcamp comments - DONE
  // make the surgeries an array, watch colt steele bootcamp comments - NOT DONE YET
  // add a field for prescriptions and notes - NOT DONE YET

  // defining patient state and setting default value of patient status to Active after creation
  const { user } = useContext(UserContext);
  const [application, setApplication] = useState({ status: "Active", steamID: user.steamId });
  // const [setWordCountJoinReason, setWordCountJoinReason] = useState();
  // const [setWordCountBackstory, setWordCountBackstory] = useState();
  const [whitelistStatus, setWhitelistStatus] = React.useState();
  const [cooldown, setCooldown] = React.useState();

  const fetchWhitelistStatus = async () => {
    fetch(`${process.env.REACT_APP_API_URL}/api/admin/user/${user._id}/whitelist`, {credentials: 'include'})
    .then((response) => response.json())
    .then((json) => setWhitelistStatus(json));
  };

  const fetchCooldown = async () => {
    fetch(`${process.env.REACT_APP_API_URL}/api/admin/user/${user._id}/cooldown`, {credentials: 'include'})
    .then((response) => response.json())
    .then((json) => isBefore(json));
  };

  const isBefore = async (cd) => {
    const then = new Date();
    const now = then.toISOString();
    if (cd != 'No') {
      const result = now < cd;
      if (result) {
        setCooldown(cd);
      } else {
        setCooldown('No Cooldown')
      }
    } else {
      setCooldown('No Cooldown')
    }   
  }

  useEffect(() => {
      fetchWhitelistStatus();
      fetchCooldown();
  }, []);
  // Post request to add a patient
  // styling
  const useStyles = makeStyles({
    root: {
      minWidth: 600,
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)",
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    sex: {
      minWidth: 600,
    },
  });

  const classes = useStyles();

  const [sex, setSex] = React.useState();
  const handleChangeSex = (event) => {
    setSex(event.target.value);
    setApplication({ ...application, sex: event.target.value });
  };
  const sexSelect = [
    {
      value: "5",
    },
    {
      value: "10",
    },
    {
      value: "15",
    },
    {
      value: "20",
    },
  ];

  const [wordCountJoinReason, setWordCountJoinReason] = useState();

  const handleChangeJoinReason = (e) => {
    setWordCountJoinReason({length: e.target.value.trim().split(' ').length})
    setApplication({ ...application, joinReason: e.target.value })
  }

  const [wordCountBackstory, setWordCountBackstory] = useState();

  const handleChangeBackstory = (e) => {
    setWordCountBackstory({length: e.target.value.trim().split(' ').length})
    setApplication({ ...application, backgroundStory: e.target.value })
  }

  const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const [error, setError] = useState();

  const onSubmit = (e) => {
    e.preventDefault();
    if (wordCountBackstory && wordCountBackstory.length < 500) {
      setError('You do not meet the backstory word count!')
    } else if (wordCountJoinReason && wordCountJoinReason.length < 100) {
      setError('You do not meet the "Why should we accept you" word count!')
    } else if (checked === false) {
      setError('You cannot proceed without agreeing to the checkbox.')
    } else {
      fetch(`${process.env.REACT_APP_API_URL}/api/applications/submit`, {
        method: "POST",
        credentials: 'include',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(application),
      })
      .then((res) => res.json())
      .then((data) => {
        props.history.push(`/my/applications`)
      });
      // redirects to patients table
    }
  };

  function diff_hours(dt2, dt1) 
  {
 
   var diff =(dt2.getTime() - dt1.getTime()) / 1000;
   diff /= (60 * 60);
   return Math.abs(Math.round(diff));
   
  }

  function diff_dates(dt2, dt1) {
    var seconds = Math.floor((dt2 - (dt1))/1000);
    var minutes = Math.floor(seconds/60);
    var hours = Math.floor(minutes/60);
    var days = Math.floor(hours/24);
    
    hours = hours-(days*24);
    minutes = minutes-(days*24*60)-(hours*60);
    seconds = seconds-(days*24*60*60)-(hours*60*60)-(minutes*60);
    return `${hours} hours ${minutes} minutes ${seconds} seconds`
}
  const form = useRef();
  return (
    <div>
      <Nav />
      <br></br>
      {cooldown && cooldown === 'No Cooldown' ?
      <Container maxWidth="md">
      {(whitelistStatus != "Accepted" && whitelistStatus != "Pending" && whitelistStatus != "Whitelisted" && whitelistStatus != "Banned" ) ?
        <ValidatorForm
          ref={form}
          onSubmit={onSubmit}
          onError={(errors) => console.log(errors)}
        >
          {/* // <form className={classes.root} noValidate autoComplete="off" onSubmit={onSubmit}> */}
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography variant="h6" component="h2">
                APPLICATION DETAILS -{" "}
                <span style={{ color: "gray" }}>Whitelist Application</span>
              </Typography>
              <Divider />
              <Typography variant="body2" component="p">
                <TextValidator
                  id="standard-basic"
                  fullWidth
                  label=" Give your Discord profile, E.g. 3RNO#0001"
                  InputLabelProps={{ shrink: true }}
                  name="application[discordID]"
                  value={application.discordID}
                  onChange={(e) =>
                    setApplication({ ...application, discordID: e.target.value })
                  }
                  validators={["required"]}
                  errorMessages={["Please add your DiscordID!"]}
                />
              </Typography>
              <Divider />
              <Typography variant="body2" component="p">
                <TextValidator
                  id="standard-basic"
                  label="What is your character's first and last name?(Name on game launcher must match)"
                  fullWidth
                  name="application[characterName]"
                  InputLabelProps={{ shrink: true }}
                  value={application.characterName}
                  onChange={(e) =>
                    setApplication({ ...application, characterName: e.target.value })
                  }
                  validators={["required"]}
                  errorMessages={["Please add your character's name!"]}
                />
              </Typography>
              <Divider />
              <Typography variant="body2" component="p">
                <TextValidator
                  id="standard-basic"
                  fullWidth
                  label="STEAMID"
                  disabled
                  InputLabelProps={{ shrink: true }}
                  name="application[steamID]"
                  value={user.steamId}
                  onChange={(e) =>
                    setApplication({ ...application, steamID: e.target.value })
                  }
                  validators={["required"]}
                  errorMessages={["Please add your SteamID!"]}
                />
              </Typography>
              <Divider />
              <Typography variant="body2" component="p">
                <TextValidator
                  id="standard-basic"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  multiline
                  label="Explain how you found out about our Server?"
                  name="application[foundOutExplanation]"
                  value={application.foundOutExplanation}
                  onChange={(e) =>
                    setApplication({ ...application, foundOutExplanation: e.target.value })
                  }
                  validators={["required"]}
                  errorMessages={["Please fill this field!"]}
                />
              </Typography>
              <Divider />
              <Typography variant="body2" component="p">
                <TextValidator
                  id="standard-basic"
                  fullWidth
                  multiline
                  InputLabelProps={{ shrink: true }}
                  label="Do you have roleplay experience on any other games? If so, which?"
                  name="application[roleplayExperience]"
                  value={application.roleplayExperience}
                  onChange={(e) =>
                    setApplication({ ...application, roleplayExperience: e.target.value })
                  }
                  validators={["required"]}
                  errorMessages={["Please fill this field!"]}
                />
              </Typography>
              <Divider />
              <Typography variant="body2" component="p">
                <TextValidator
                  id="standard-basic"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  multiline
                  label="Why should we accept you? (At least 100 words)"
                  name="application[joinReason]"
                  value={application.joinReason}
                  onChange={handleChangeJoinReason}
                  validators={["required"]}
                  errorMessages={["Please fill this field!"]}
                />
              </Typography>
              <Divider />
              <Typography variant="body2" component="p">
                <TextValidator
                  id="standard-basic"
                  fullWidth
                  label="Enter the hidden word found on our Web Page. (Hint: do not skim read)"
                  name="application[hiddenWord]"
                  InputLabelProps={{ shrink: true }}
                  value={application.hiddenWord}
                  onChange={(e) =>
                    setApplication({ ...application, hiddenWord: e.target.value })
                  }
                  validators={["required"]}
                  errorMessages={["Please fill this field!"]}
                />
              </Typography>
              <Divider />
              <Typography variant="body2" component="p">
                <TextValidator
                  id="standard-basic"
                  fullWidth
                  multiline
                  label="What does NVL mean? When does it apply?" 
                  name="application[valueOfLifeExplanation]"
                  InputLabelProps={{ shrink: true }}
                  value={application.valueOfLifeExplanation}
                  onChange={(e) =>
                    setApplication({ ...application, valueOfLifeExplanation: e.target.value })
                  }
                  validators={["required"]}
                  errorMessages={["Please fill this field!"]}
                />
              </Typography>
              <Divider />
              <Typography variant="body2" component="p">
                <TextValidator
                  id="standard-basic"
                  fullWidth
                  multiline
                  InputLabelProps={{ shrink: true }}
                  label="Give a detailed backstory of your character before entering the Zone, including the traits described below. (500 words minimum.)"
                  name="application[backgroundStory]"
                  value={application.backgroundStory}
                  onChange={handleChangeBackstory}
                  validators={["required"]}
                  errorMessages={["Please fill this field!"]}
                />
              </Typography>
              <Divider />
              <Typography variant="body2" component="p">
                <TextValidator
                  id="standard-basic"
                  fullWidth
                  multiline
                  label="Give three(3) positive traits your character will possess during their life on StalkerZ. Explain how they will affect your character." 
                  name="application[positiveTraits]"
                  InputLabelProps={{ shrink: true }}
                  value={application.positiveTraits}
                  onChange={(e) =>
                    setApplication({ ...application, positiveTraits: e.target.value })
                  }
                  validators={["required"]}
                  errorMessages={["Please fill this field!"]}
                />
              </Typography>
              <Divider />
              <Typography variant="body2" component="p">
                <TextValidator
                  id="standard-basic"
                  fullWidth
                  multiline
                  label="Give three(3) negative traits your character will possess during their life on StalkerZ. Explain how they will affect your character." 
                  name="application[positiveTraits]"
                  InputLabelProps={{ shrink: true }}
                  value={application.negativeTraits}
                  onChange={(e) =>
                    setApplication({ ...application, negativeTraits: e.target.value })
                  }
                  validators={["required"]}
                  errorMessages={["Please fill this field!"]}
                />
              </Typography>
              <Divider />
              <Typography variant="body2" component="p">
                <TextValidator
                  id="standard-basic"
                  fullWidth
                  label="When is it allowed to go out of character (OOC) while playing on the server?"
                  name="application[allowedOOC]"
                  InputLabelProps={{ shrink: true }}
                  value={application.allowedOOC}
                  onChange={(e) =>
                    setApplication({ ...application, allowedOOC: e.target.value })
                  }
                  validators={["required"]}
                  errorMessages={["Please fill this field!"]}
                />
              </Typography>
              <Divider />
              <Typography variant="body2" component="p">
                <TextValidator
                  id="standard-basic"
                  fullWidth
                  label="You are holding someone up, you give them a demand to put their hands up or you will shoot. How much time must you give them to comply?"
                  name="application[initiationComplianceSeconds]"
                  InputLabelProps={{ shrink: true }}
                  value={application.initiationComplianceSeconds}
                  onChange={(e) =>
                    setApplication({ ...application, initiationComplianceSeconds: e.target.value })
                  }
                  validators={["required"]}
                  errorMessages={["Please fill this field!"]}
                />
              </Typography>
              <Divider />
              <Typography variant="body2" component="p">
                <TextValidator
                  id="standard-basic"
                  fullWidth
                  multiline
                  label="You just seen someone break a rule right in front of you, what do you do?"
                  name="application[rulebreakInfrontOfYou]"
                  InputLabelProps={{ shrink: true }}
                  value={application.rulebreakInfrontOfYou}
                  onChange={(e) =>
                    setApplication({ ...application, rulebreakInfrontOfYou: e.target.value })
                  }
                  validators={["required"]}
                  errorMessages={["Please fill this field!"]}
                />
              </Typography>
              <Divider />
              <Typography variant="body2" component="p">
                <TextValidator
                  id="standard-basic"
                  fullWidth
                  multiline
                  label="What are the current Factions on the server?"
                  name="application[currentFactions]"
                  InputLabelProps={{ shrink: true }}
                  value={application.currentFactions}
                  onChange={(e) =>
                    setApplication({ ...application, currentFactions: e.target.value })
                  }
                  validators={["required"]}
                  errorMessages={["Please fill this field!"]}
                />
              </Typography>
              <Divider />
              <Typography variant="body2" component="p">
                <TextValidator
                  id="standard-basic"
                  fullWidth
                  multiline
                  label="Which Faction, if any, would you be most interested in? Tell us why?"
                  name="application[interestedFaction]"
                  InputLabelProps={{ shrink: true }}
                  value={application.interestedFaction}
                  onChange={(e) =>
                    setApplication({ ...application, interestedFaction: e.target.value })
                  }
                  validators={["required"]}
                  errorMessages={["Please fill this field!"]}
                />
              </Typography>
              <Divider />
              <Typography variant="body2" component="p">
                <TextValidator
                  id="standard-basic"
                  fullWidth
                  multiline
                  label="Give 3 full scenarios of when you should value your life."
                  name="application[ScenariosValueOfLife]"
                  InputLabelProps={{ shrink: true }}
                  value={application.ScenariosValueOfLife}
                  onChange={(e) =>
                    setApplication({ ...application, ScenariosValueOfLife: e.target.value })
                  }
                  validators={["required"]}
                  errorMessages={["Please fill this field!"]}
                />
              </Typography>
              <Divider />
              <Typography variant="body2" component="p">
                <TextValidator
                  id="standard-basic"
                  fullWidth
                  multiline
                  label="Tell us what you think good sportsmanship is and why it is important."
                  name="application[sportsmanship]"
                  InputLabelProps={{ shrink: true }}
                  value={application.sportsmanship}
                  onChange={(e) =>
                    setApplication({ ...application, sportsmanship: e.target.value })
                  }
                  validators={["required"]}
                  errorMessages={["Please fill this field!"]}
                />
              </Typography>
              <Divider />
              <Typography variant="body2" component="p">
                <TextValidator
                  id="standard-basic"
                  fullWidth
                  multiline
                  label="In your own words, give a detailed explanation of the NLR rule."
                  name="application[nlrExplanation]"
                  InputLabelProps={{ shrink: true }}
                  value={application.nlrExplanation}
                  onChange={(e) =>
                    setApplication({ ...application, nlrExplanation: e.target.value })
                  }
                  validators={["required"]}
                  errorMessages={["Please fill this field!"]}
                />
              </Typography>
              <Divider />
              <Typography variant="body2" component="p">
                <TextValidator
                  id="standard-basic"
                  fullWidth
                  multiline
                  label="You were part of an encounter in which you died, when can you grab your gear?"
                  name="application[gearGrab]"
                  InputLabelProps={{ shrink: true }}
                  value={application.gearGrab}
                  onChange={(e) =>
                    setApplication({ ...application, gearGrab: e.target.value })
                  }
                  validators={["required"]}
                  errorMessages={["Please fill this field!"]}
                />
              </Typography>
              <Divider />
              <FormControlLabel control={<Checkbox checked={checked} onChange={handleChange} />} label="Please confirm you understand that if you change your character for any reason you must submit a new whitelist application with your new character's backstory and traits." />
              <Divider />
        {/* <Typography variant="body2" component="p">
        <TextValidator
        id="standard-basic" 
        label="SUPERVISOR"
        name="patient[supervisor]"
        value={patient.supervisor}
        validators={['required']}
        errorMessages={['Please add the supervising doctor of the patient!']}
        onChange={e => setPatient({...patient, supervisor: e.target.value})} 
        />
        </Typography>
        <Divider />                 */}
            </CardContent>
            {error &&
             <Alert severity="error">{error}</Alert>
            }
            <CardActions>
              <Button
                type="submit"
                size="small"
                color="primary"
                variant="outlined"
              >
                SUBMIT
              </Button>
            </CardActions>
          </Card>
        </ValidatorForm>
        :
        <h1>Cannot perform this action because your application is {whitelistStatus}</h1>
        }
      </Container> : 
      <Container maxWidth="md"> <h1>A cooldown has been applied for the next {cooldown != 'No Cooldown' ? diff_dates(new Date(cooldown), new Date()) : ''} hours</h1></Container>
}
    </div>
  );
}
