import React, { useState, useEffect, useContext } from "react";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import LockIcon from "@material-ui/icons/Lock";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CssBaseline from "@material-ui/core/CssBaseline";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Tooltip from "@material-ui/core/Tooltip";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import Fade from "@material-ui/core/Fade";
import Nav from "./Nav";
import { UserContext } from "./UserContext";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export default function Album() {
  const classes = useStyles();

  const { user } = useContext(UserContext);
  const [applications, setApplications] = React.useState();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/admin/user/${user._id}/applications`, {credentials: 'include'})
      .then((response) => response.json())
      .then((json) => setApplications(json));
  }, []);

  return (
    <React.Fragment>
      <Nav />
      <CssBaseline />
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Fade in={true} timeout={3500}>
            <Container maxWidth="sm">
              <Typography
                component="h1"
                variant="h2"
                align="center"
                color="textPrimary"
                gutterBottom
              >
                Your applications
              </Typography>
            </Container>
          </Fade>
        </div>
      </main>
      <Container maxWidth="md">
      <TableContainer
              className={classes.table}
              component={Paper}
              sx={{width: '800px'}}
              style={{ overflowY: "hidden" }}
            >
              <div style={{ width: "auto", overflowX: "scroll" }}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <strong>DiscordID</strong>
                      </TableCell>
                      <TableCell>
                        <strong>SteamID</strong>
                      </TableCell>
                      {/* <TableCell align="right">
                        <strong>SteamID</strong>
                      </TableCell> */}
                      {/* <TableCell align="right">
                        <strong>Username</strong>
                      </TableCell> */}
                      {/* <TableCell align="right">
                        <strong>Staff Handler</strong>
                      </TableCell> */}
                      <TableCell align="right">
                        <strong>Status</strong>
                      </TableCell>
                      <TableCell align="right">
                        <strong>Reason</strong>
                      </TableCell>
                      <TableCell align="center">
                        <strong>Action</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {applications && applications.length > 0 && (
                    <>
                    {applications.map((application) => (
                      <>
                          <Slide
                            direction="up"
                            in={applications}
                            mountOnEnter
                            unmountOnExit
                          >
                            <TableRow key={application._id}>
                              <TableCell>{application.discordID.substring(0, 80)}</TableCell>
                              <TableCell align="right">{application.steamID.substring(0, 80)}</TableCell>
                              {/* {application.handler &&
                                application.handler.name != undefined && (
                                  <TableCell align="right">
                                    {application.handler.name}
                                  </TableCell>
                                )}
                              {!application.handler &&
                                  <TableCell align="right">
                                    Pending handler
                                  </TableCell>
                                } */}
                                <TableCell align="right">
                                {application.status}
                              </TableCell>
                              <TableCell align="right">
                                {application.reason}
                              </TableCell>
                              <TableCell align="center">
                                <Tooltip title="Details">
                                  <IconButton
                                    aria-label="details"
                                    component={Link}
                                    to={`/applications/${application._id}`}
                                  >
                                    <PersonPinIcon />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          </Slide>
                      </>
                    ))}
                   </>
                  )}
                  </TableBody>
                </Table>
              </div>
            </TableContainer>
            </Container>
    </React.Fragment>
  );
}
