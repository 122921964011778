import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";

export default function ErrorScreen() {
  return (
    <div>
      <Container maxwidth="md">
        <h2>Page not found / Unauthorized</h2>
      </Container>
    </div>
  );
}
