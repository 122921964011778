import React, { useState, useEffect, useContext } from "react";
import Container from "@material-ui/core/Container";
import TextField from '@material-ui/core/TextField';
import Nav from "./Nav";
import { UserContext } from "./UserContext";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import SearchBar from "material-ui-search-bar";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "auto",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    //   backgroundColor: red[500],
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const [player, setPlayer] = useState({});


const [steamID, setSteamID] = useState('');

const playerLookUp = (steamID) => {
    fetch(`${process.env.REACT_APP_API_URL}/api/admin/users/${steamID}/playtime`, {credentials: 'include'})
        .then((response) => response.json())
        .then((json) => setPlayer(json));
}

var returnElapsedTime = function(epoch) {
    //We are assuming that the epoch is in seconds
    var hours = epoch / 3600,
        minutes = (hours % 1) * 60,
        seconds = (minutes % 1) * 60;
    return Math.floor(hours) + " hours, " + Math.floor(minutes) + " minutes, " + Math.round(seconds) + " seconds";
  }

var timePlayedInFaction = function(totalTime, factionTime) {
const result = totalTime - factionTime;
return returnElapsedTime(result);
}

const user = player && player.user ? player.user : '';
return (
    <div>
      <Nav />
      <Container maxWidth="md">
        <br></br>
        <Card className={classes.root}>
          <CardHeader
            title='PLAYER TIME LOOKUP'
          />
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
            <TextField
            InputLabelProps={{ shrink: true }}
            label="Player SteamID 64"
            variant="outlined"
            required
            value={steamID}
            onChange={e => setSteamID(e.target.value)}
            />
            </Typography>
            <br></br>
            <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  onClick={() => playerLookUp(steamID)}
                  >
                  FETCH PLAYER PLAYTIME
                </Button>
                { player && player.error &&
                <p>{player.error} with SteamID 64: {player.steamID}</p>
                }
                {player && player.item && player.item.playtime && 
                <div>
                <p>Player has {returnElapsedTime(player.item.playtime)} and {player.item.sessions} sessions on the server.</p>
                <p>Player Bohemia ID: {player.item.identities.bohemia.id}</p>
                </div>
                }
          </CardContent>
          </Card>
          <br></br>
          {player && player.user != undefined &&
          <>
                   <Typography variant="h5" gutterBottom>
                    FACTION HISTORY OF {user.name} {user.steamId}
                  </Typography>
                <TableContainer
              className={classes.table}
              component={Paper}
              style={{ overflowY: "hidden" }}
            >
              <div style={{ width: "auto", overflowX: "scroll" }}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <strong>Previous Faction</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Previous Faction Standing</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Total time played since leaving previous faction</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Current Faction</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Faction Change Date</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {user && user.factionHistory && user.factionHistory.length > 0 && user.factionHistory.map((history) => (
                        <>
                        <Slide
                          direction="up"
                          in={user}
                          mountOnEnter
                          unmountOnExit
                        >
                          <TableRow key={user.id}>
                            <TableCell>{history.previousFaction}</TableCell>
                            <TableCell>{history.standing}</TableCell>
                            <TableCell>{timePlayedInFaction(player.item.playtime, history.previousFactionPlaytime)}</TableCell>
                            <TableCell>{history.nextFaction}</TableCell>
                            <TableCell>{new Date(history.changedFactionOn).toDateString()}</TableCell>
                          </TableRow>
                        </Slide>
                        </>
                      ))}
                  </TableBody>
                </Table>
              </div>
            </TableContainer>
            </>
            }
      </Container>
    </div>
  );
}
