import React, { useState, useEffect, useContext } from "react";
import Container from "@material-ui/core/Container";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Nav from "./Nav";
import { UserContext } from "./UserContext";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import clsx from "clsx";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import SwipeableViews from "react-swipeable-views";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Tooltip from "@material-ui/core/Tooltip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "auto",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    //   backgroundColor: red[500],
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function Dashboard() {
  const classes = useStyles();
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/admin/logs`, {credentials: 'include'})
      .then((response) => response.json())
      .then((json) => setLogs(json));
  }, []);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  

  return (
    <div>
      <Nav />
      <Container maxWidth="md">
        <br></br>
        <Card className={classes.root}>
          <CardHeader
            title='ACTION LOGS BY OTHER STAFF'
          />
          <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="WHITELIST LOGS" {...a11yProps(0)} />
          <Tab label="FACTION LOGS" {...a11yProps(1)} />
        </Tabs>
          <CardContent>
          <TabPanel value={value} index={0}>
            <Typography variant="body2" color="textSecondary" component="p">
            {logs && logs.map((log) => (
                      <>
                      {!log.isFactionLog &&
                      <p>
                       {log.staffRole} {log.staffName} (SteamID: {log.staffSteamID}) <strong>{log.action}</strong> from user with SteamID: <strong>{log.userSteamID}</strong> on {new Date(log.date).toDateString()}

                      </p>
                      }
                      </>
             ))}
            </Typography>
            </TabPanel>
            <TabPanel value={value} index={1}>
            <Typography variant="body2" color="textSecondary" component="p">
            {logs && logs.map((log) => (
                      <>
                      {log.isFactionLog &&
                      <p>
                       {log.staffRole} {log.staffName} (SteamID: {log.staffSteamID}) <strong>{log.action}</strong> from user with SteamID: <strong>{log.userSteamID}</strong> on {new Date(log.date).toDateString()} <strong>from <u>{log.fromFaction}</u> to <u>{log.toFaction}</u> on <u>{log.standing}</u></strong>

                      </p>
                      }
                      </>
             ))}
            </Typography>
            </TabPanel>
          </CardContent>
          </Card>
      </Container>
    </div>
  );
}
