import React, { useState, useEffect, useRef } from "react";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Container from "@material-ui/core/Container";
import Nav from "./Nav";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import SwipeableViews from "react-swipeable-views";
import PropTypes from "prop-types";
import Toolbar from "@material-ui/core/Toolbar";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import FormControl from "@material-ui/core/FormControl";
import TableCell from "@material-ui/core/TableCell";
import SearchBar from "material-ui-search-bar";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";
import Slide from "@material-ui/core/Slide";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import SendIcon from "@material-ui/icons/Send";
import AuthService from ".././services/auth.service";
// Dialog component from Material UI
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import EditIcon from "@material-ui/icons/Edit";
import TablePagination from '@material-ui/core/TablePagination';
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  table: {
    // minWidth: 750,
    // maxWdith: 750,
  },
  addEmployeeButton: {
    backgroundColor: "black",
  },
  searchBar: {
    width: "70%",
  },
  filterBar: {
    backgroundColor: "white",
    width: "30%",
    marginLeft: "1%",
  },
  searchContainer: {
    display: "flex",
    width: "100%",
  },
  toolbar: theme.mixins.toolbar,
  content: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
}));

export default function SystemAdministration(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSearchChange = (event) => {
    setSearching(event.target.value);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // --------------------------

  /// GETTING ALL USERS

  // const [currentUser, setCurrentUser] = useState({

  // });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [users, setUsers] = useState([]);
  const [lonerUsers, setLonerUsers] = useState([]);
  const [checked, setChecked] = React.useState(false);
  const [checkedBadStanding, setCheckedBadStanding] = React.useState(false);
  const [checkedGoodStanding, setCheckedGoodStanding] = React.useState(false);

  // const [accessToken, setAccessToken] = useState(undefined);

  // GET/Fetch all users, listener for users

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/admin/users`, {credentials: 'include'})
      .then((response) => response.json())
      .then((json) => filterUsers(json));
  }, []);

  const filterUsers = (users) => {
    const result = users && users.filter(user => user.faction === 'Loner' && user.whitelist === 'Whitelisted');  
    setLonerUsers(result);
    const result2 = users && users.filter(user => user.faction !== 'Loner'); 
    setUsers(result2); 
  };

  // dialog for adding users

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  // user object to sent for the POST request

  const [user, setUser] = React.useState({});

  // Post request to add a user

  const onUserSubmit = (e) => {
    e.preventDefault();
    fetch(`${process.env.REACT_APP_API_URL}/api/admin/users/create`, {
      method: "POST",
      credentials: 'include',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(user),
    })
      .then((res) => res.json())
      .then((json) => setUsers(json.user))
      .then((json) =>
        setUser({
          name: "",
          email: "",
          password: "",
          role: "",
        })
      )
      .then(setOpen(false))
      .then(props.history.go(0))
      .then(props.history.go(0));
  };

  // delete request

  // const deleteUser = (userID) => {
  //   const userReceived = AuthService.getCurrentUser();
  //   fetch(`http://localhost:8080/admin/users/${userID}/`, {
  //     method: 'DELETE',
  //     headers: new Headers({
  //       'Authorization': 'Bearer ' + userReceived.accessToken,
  //       "Content-Type": "application/json",
  //     }),
  //   })
  //   .then(response => response.json())
  //   console.log(userID + ":user deleted!!!")
  // };

  // DELETE patient on click event on line 177

  const deleteUser = (userID) => {
    fetch(`${process.env.REACT_APP_API_URL}/api/admin/users/` + userID, {
      method: "DELETE",
      credentials: 'include',
    })
      .then((response) => response.json())
      .then(props.history.go(0));
  };

  // edit request

  // declaring temporary state for the edit user
  const [editUser, setEditUser] = useState({});

  // Edit Dialog
  const [openEditUser, setOpenEditUser] = React.useState(false);

  // id passes the selected user through the icon button modify into the editUser state

  const handleClickOpenEditUser = (id) => {
    setEditUser(id);
    setOpenEditUser(true);
  };

  const handleChangeCheckbox = (event) => {
    setChecked(event.target.checked);
    setEditUser({ ...editUser, standing: 'First time' });
  };

  const handleChangeCheckboxBadStanding = (event) => {
    setCheckedBadStanding(event.target.checked);
    setEditUser({ ...editUser, standing: 'Bad standing' });
  };

  const handleChangeCheckboxGoodStanding = (event) => {
    setCheckedGoodStanding(event.target.checked);
    setEditUser({ ...editUser, standing: 'Good standing' });
  };

  const handleCloseEditUser = () => {
    setOpenEditUser(false);
  };

  const [error, setError] = React.useState('');


  // Put request to edit the specific user of the patient

  const onEditedUserSubmit = (e) => {
    e.preventDefault();
    if (!checked && !checkedBadStanding && !checkedGoodStanding) {
      setError('Choose one of the three.');
    } else {
      fetch(`${process.env.REACT_APP_API_URL}/api/admin/users/${editUser._id}/faction/edit`, {
        method: "PUT",
        credentials: 'include',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ editUser }),
      })
        .then((res) => res.json())
        .then((json) =>
          setEditUser({
            name: "",
            email: "",
            role: "",
            faction: "",
          })
        )
        .then(setOpenEditUser(false))
        .then(setCheckedGoodStanding(false))
        .then(setCheckedBadStanding(false))
        .then(setChecked(false))
    }
  };

  // date picker

  // const [selectedDate, setSelectedDate] = React.useState(new Date('1999-01-01T00:00:00'));

  // const handleDateChange = (date) => {
  //     setSelectedDate(date);
  //     setUser({...user, birthday: date})
  //     setEditUser({...editUser, birthday: date})
  //   };

  // role dropdown options

  const [whitelist, setWhitelist] = React.useState();

  const handleRoleChangeEditUser = (event) => {
    // setRole(event.target.value);
    const previousFaction = editUser.faction
    setEditUser({ ...editUser, faction: event.target.value, previousFaction: previousFaction, nextFaction: event.target.value });
    // setEditUser({...editUser, role: event.target.value});
  };
  console.log(editUser);

  const roles = [
    {
      value: "Loner",
    },
    {
      value: "Military",
    },
    {
      value: "Freedom",
    },
    {
      value: "Duty",
    },
    {
      value: "Bandits",
    },
    {
    value: "Freestalkers",
    },
    {
    value: "Renegades",
    },
    {
    value: "Clear Sky",
    },
    {
    value: "Ecologists",
    },
    {
    value: "Monolith",
    },
  ];

  // department dropdown options

  const [department, setDepartment] = React.useState();

  const handleDepartmentChange = (event) => {
    setDepartment(event.target.value);
    setUser({ ...user, department: event.target.value });
    // setEditUser({...editUser, role: event.target.value});
  };

  const handleDepartmentChangeEditUser = (event) => {
    // setDepartment(event.target.value);
    setEditUser({ ...editUser, department: event.target.value });
    // setEditUser({...editUser, role: event.target.value});
  };

  const departments = [
    {
      value: "Psychology",
    },
    {
      value: "Pathology",
    },
    {
      value: "Surgery",
    },
  ];

  // declaring state for searched value to be passed to requestSearch from the SearchBar component
  const [searching, setSearching] = React.useState("Faction");

  const [searched, setSearched] = React.useState("");

  // declaring state in order to keep state that was there prior to searching
  // that way we return to the original state without having to refresh, when we are done searching

  const [previousState, setPreviousState] = React.useState([]);

  // request search function that filters the search based on "searching" value (doctors/patients)
  // basic .includes function that returns the filtered patients on real time
  // we also set the previous state to the original patients state before searching, if the previousState.length equals to 0, which means
  // that we havent searched for anything yet

  const requestSearch = (searchedVal) => {
    if (searching === "SteamID") {
      const filteredUsers = users.filter((user) => {
        return user.steamId
          .toLowerCase()
          .includes(searchedVal.toLowerCase());
      });

      setUsers((prevState) => {
        if (previousState.length == 0) {
          setPreviousState(prevState);
        }
        return filteredUsers;
      });
  } else if (searching === "Faction") {
    const filteredUsers = users.filter((user) => {
      return user.faction
        .toLowerCase()
        .includes(searchedVal.toLowerCase());
    });

    setUsers((prevState) => {
      if (previousState.length == 0) {
        setPreviousState(prevState);
      }
      return filteredUsers;
    });
}};

  // we cancel the search and set the patients to the original state through previousState

  const cancelSearch = () => {
    setSearched("");
    setUsers(previousState);
    // requestSearch(searched);
  };

  // for form validation in notes
  const form = useRef();
  const form2 = useRef();

  return (
    <div>
      <Nav />
      <Toolbar />
      <div className={classes.content}>
        <Container maxwidth="sm" className={classes.toolbar}>
          <p></p>
          <AppBar position="static" color="default">
            <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="FACTION MEMBERS" {...a11yProps(0)} />
          <Tab label="LONERS" {...a11yProps(1)} />
        </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
            <br></br>
        <div className={classes.searchContainer}>
          <SearchBar
            className={classes.searchBar}
            value={searched}
            cancelOnEscape={true}
            onChange={(searchVal) => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
          />
          <FormControl variant="outlined" className={classes.filterBar}>
            {/* <InputLabel id="demo-simple-select-outlined-label">Search Filter</InputLabel> */}
            <Select
              style={{ height: 49 }}
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={searching}
              onChange={handleSearchChange}
            >
              <MenuItem key={1} value={"SteamID"}>SteamID</MenuItem>
              <MenuItem key={2} value={"Faction"}>Faction</MenuItem>
            </Select>
          </FormControl>
        </div>
        <br></br>
            <TableContainer
              className={classes.table}
              component={Paper}
              style={{ overflowY: "hidden" }}
            >
              <div style={{ width: "auto", overflowX: "scroll" }}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <strong>Name</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Username</strong>
                      </TableCell>
                      <TableCell>
                        <strong>System Role</strong>
                      </TableCell>
                      <TableCell>
                        <strong>SteamID</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Faction</strong>
                      </TableCell>
                      <TableCell align="center">
                        <strong>Action</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users &&
                      users.map((user) => (
                        <Slide
                          direction="up"
                          in={users}
                          mountOnEnter
                          unmountOnExit
                        >
                          <TableRow key={user.id}>
                            <TableCell>{user.name}</TableCell>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>{user.role}</TableCell>
                            <TableCell>{user.steamId}</TableCell>
                            <TableCell>{user.faction}</TableCell>
                            <TableCell align="center">
                              <Tooltip title="Modify">
                                <IconButton
                                  aria-label="modify"
                                  size="small"
                                  onClick={() => handleClickOpenEditUser(user)}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        </Slide>
                      ))}
                  </TableBody>
                </Table>
              </div>
            </TableContainer>
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
            <TableContainer
              className={classes.table}
              component={Paper}
              style={{ overflowY: "hidden" }}
            >
              <div style={{ width: "auto", overflowX: "scroll" }}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <strong>Name</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Username</strong>
                      </TableCell>
                      <TableCell>
                        <strong>System Role</strong>
                      </TableCell>
                      <TableCell>
                        <strong>SteamID</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Faction</strong>
                      </TableCell>
                      <TableCell align="center">
                        <strong>Action</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {lonerUsers &&
                      lonerUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user) => (
                        <Slide
                          direction="up"
                          in={users}
                          mountOnEnter
                          unmountOnExit
                        >
                          <TableRow key={user.id}>
                            <TableCell>{user.name}</TableCell>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>{user.role}</TableCell>
                            <TableCell>{user.steamId}</TableCell>
                            <TableCell>{user.faction}</TableCell>
                            <TableCell align="center">
                              <Tooltip title="Modify">
                                <IconButton
                                  aria-label="modify"
                                  size="small"
                                  onClick={() => handleClickOpenEditUser(user)}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        </Slide>
                      ))}
                  </TableBody>
                </Table>
              </div>
            </TableContainer>
            <TablePagination
        rowsPerPageOptions={[100, 200, 300, 600]}
        component="div"
        count={lonerUsers&& lonerUsers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
            </TabPanel>
          </SwipeableViews>
          {/* EDIT USER DIALOG */}
          {/* <Dialog open={openEditUser} onClose={handleCloseEditUser} aria-labelledby="form-dialog-title">
<ValidatorForm
            ref={form2}
            onSubmit={onEditedUserSubmit}
            onError={errors => console.log(errors)}>
          <DialogTitle id="form-dialog-title">Edit User</DialogTitle> */}
          <Dialog
            open={openEditUser}
            onClose={handleCloseEditUser}
            aria-labelledby="form-dialog-title"
          >
            <ValidatorForm
              ref={form2}
              onSubmit={onEditedUserSubmit}
              onError={(errors) => console.log(errors)}
            >
              <DialogTitle id="form-dialog-title">
                USER FACTION MODIFICATION
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Changing the faction of {editUser.name}
                </DialogContentText>
                <TextValidator
                  variant="outlined"
                  id="standard-select-condition"
                  select
                  fullWidth
                  label="Faction"
                  value={editUser.faction || ""}
                  name="editUser[faction]"
                  validators={["required"]}
                  errorMessages={["Please change the user's faction status!"]}
                  onChange={handleRoleChangeEditUser}
                >
                  {roles.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.value}
                    </MenuItem>
                  ))}
                </TextValidator>
                {!checked &&
                <FormControlLabel control={<Checkbox checked={checkedGoodStanding} onChange={handleChangeCheckboxGoodStanding} />} label="Good standing" />
                }
                {!checked &&
                <FormControlLabel control={<Checkbox checked={checkedBadStanding} onChange={handleChangeCheckboxBadStanding} />} label="Bad standing" />
                }
                <br></br>
                {!checkedBadStanding && !checkedGoodStanding &&
                <FormControlLabel control={<Checkbox checked={checked} onChange={handleChangeCheckbox} />} label="First time joining a faction?" />
                }
              {error &&
              <p><strong>CHOOSE ONE OF THE THREE OPTIONS</strong></p>
              }
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseEditUser} color="primary">
                  Cancel
                </Button>
                <Button type="submit" color="primary">
                  Save
                </Button>
              </DialogActions>
            </ValidatorForm>
          </Dialog>
        </Container>
      </div>
    </div>
  );
}
