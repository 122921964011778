import "./App.css";
import Nav from "./components/Nav";
import Tasks from "./components/Tasks";
import Login from "./components/Login";
import Logs from "./components/Logs";
import PlayerLookup from "./components/PlayerLookup";
import ErrorScreen from "./components/ErrorScreen";
import Patients from "./components/Patients";
import ApplicationSubmitted from './components/ApplicationSubmitted';
import Dashboard from "./components/Dashboard";
import PatientDetails from "./components/PatientDetails";
import PatientDetailsEdit from "./components/PatientDetailsEdit";
import PatientCreate from "./components/PatientCreate";
import Tracking from './components/Tracking';
import Administration from "./components/Administration";
import Users from "./components/Users";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AuthService from "./services/auth.service";
import { UserContext } from "./components/UserContext";
import Factions from "./components/Factions";
import React, { useState, useEffect, useContext, useMemo } from "react";

function App() {
  const [user, setUser] = useState(null);

  // this prevents this providerValue changing unless value or setValue changes
  const value = useMemo(() => ({ user, setUser }), [user, setUser]);

  useEffect(() => {
    async function fetchUser() {
      const receivedUser = await AuthService.getCurrentUser();
      if (receivedUser) {
        setUser(receivedUser);
      }
    }
    fetchUser();
  }, []);

  useEffect(() => {
    setUser(user);
  }, [user]);

  return (
    <Router>
      <div className="App">
        <UserContext.Provider value={value}>
          {user == null && (
            <Switch>
              <Route path="/login" exact component={Login}></Route>
              <Route path="*" exact={true} component={Login} />
            </Switch>
          )}
          {user != null && (
            <>
              <Switch>
                <Route path="/" exact component={Dashboard}></Route>
                {(user.role === "admin" || user.role === "staff") && (
                <Route path="/applications" exact component={Patients} />
                )}
                {(user.role === "admin" || user.role === "staff") && (
                  <Route
                    path="/administration"
                    exact
                    component={Administration}
                  />
                )}
                <Route
                  path="/applications/create"
                  exact
                  component={PatientCreate}
                />
                <Route path="/applications/:id" exact component={PatientDetails} />
                <Route path="/my/applications" exact component={ApplicationSubmitted} />
                <Route
                  path="/applications/:id/edit"
                  exact
                  component={PatientDetailsEdit}
                />
                 {user.role === "admin" && (
                  <Route path="/users" exact component={Users} />
                )}
                {(user.role === "admin" || user.role === "staff") && (
                  <Route
                    path="/tracking"
                    exact
                    component={Tracking}
                  />
                )}
                 {(user.role === "admin" || user.role === "staff") && (
                  <Route
                    path="/logs"
                    exact
                    component={Logs}
                  />
                )}
                {(user.role === "admin" || user.role === "staff") && (
                  <Route
                    path="/lookup"
                    exact
                    component={PlayerLookup}
                  />
                )}
                {(user.role === "admin" || user.role === "staff") && (
                  <Route
                    path="/factions"
                    exact
                    component={Factions}
                  />
                )}
                <Route path="*" exact={true} component={ErrorScreen} />
              </Switch>
            </>
          )}
        </UserContext.Provider>
      </div>
    </Router>
  );
}

export default App;
