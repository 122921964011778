import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import AuthService from "../services/auth.service";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      <Link color="inherit" href="https://discord.gg/StalkerZ">
        StalkerZ
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "black",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "black",
  },
  error: {
    color: "red",
  },
}));

export default function SignIn(props) {
  const classes = useStyles();
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loggedUser, setloggedUser] = useState();

// const steamLogin = async () => {
//   fetch('/api/auth/steam', {
//     crossDomain:true,
//     method: 'GET',
//     headers: {'Content-Type':'application/json'},
//   })
//   .then((response) => response.json())
//   .then((json) => setloggedUser(json));
// };

// const handleSteamLogin = (e) => {
//   e.preventDefault()
//   steamLogin();
// }

const handleSteamLogin = () => {
  const popupWindow = window.open(
    `${process.env.REACT_APP_API_URL}` + "/api/auth/steam",
    "_blank",
    "width=800, height=600",
  );
  if (window.focus) popupWindow.focus();
};

useEffect(() => {
  window.addEventListener("message", event => {
    if (event.origin !== `${process.env.REACT_APP_API_URL}`) return;
    const { token, ok } = event.data;
    if (ok) {
      fetch(`${process.env.REACT_APP_API_URL}/api/auth/validate`, {credentials: 'include'})
      .then((response) => response.json())
      .then((json) =>  localStorage.setItem("user", JSON.stringify(json)))
      .then(props.history.push("/"))
      .then(window.location.reload());
    }
  });
}, []);

  const handleLogin = (e) => {
    e.preventDefault();

    AuthService.login(email, password).then(
      () => {
        props.history.push("/");
        window.location.reload();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setMessage("Invalid login credentials! Please try again.");
      }
    );
  };

  // const [currentUser, setCurrentUser] = useState({
  // })

  // useEffect(() => {
  //   const user = AuthService.getCurrentUser();
  //   if (user) {
  //     setCurrentUser(user);
  //   }
  //   if (currentUser.role != undefined) {
  //     props.history.push("/dashboard");
  //   }

  // }, []);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <form className={classes.form} noValidate onSubmit={handleLogin}>
          <img src="https://i.imgur.com/5dhiJgO.png"/>
          {/* <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            type="text"
            label="Username"
            name="email"
            onChange={(e) => setEmail(e.target.value)}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            type="password"
            label="Password"
            name="password"
            onChange={(e) => setPassword(e.target.value)}
            autoFocus
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button> */}
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleSteamLogin}
          >
            Login with Steam
          </Button>
          <span className={classes.error}>{message}</span>
          <Grid container>
            <Grid item xs>
              Do not contact staff for account issues, authentication is handled through steam. If logging in through steam doesn't work, please try it a few times and you'll eventually get in.
              <p><strong>Use Google Chrome or Microsoft Edge!</strong></p>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
